.CardList {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 35px;
}

@media (max-width: 1200px) {
    .CardList {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 735px) {
    .CardList {
        width: 95%;
        grid-template-columns: 1fr 1fr;
    }
}