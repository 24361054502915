.RoadContainer {
    position: relative;
    display: flex;
    width: 1400px;
    margin: auto;
}

.ControlsContainer {
    display: flex;
    max-width: 1300px;
    margin: 10px auto;
    gap: 10px;
}

.Desktop {
    margin-top: -70px;
    display: flex;           
    justify-content: center; 
    align-items: center;     
    height: 100vh;
}

.Img {
    background-image: url(../../asserts/icons/desktop.png);
    background-size: cover;
    width: 30vw;
    height: 30vw;
}

@media (max-width: 1400px) {
    .ControlsContainer {
        margin-left: 50px;
    }
} 

@media (max-width: 767px) {
    .ControlsContainer {
        flex-direction: column;
        margin-right: 50px;
    }
} 
