.Grass {
    position: relative;
    width: 1300px;
    height: 250px;
    border-radius: 10px 10px 0px 0px;
    background: rgb(57, 155, 57); 
    z-index: 10;
}

.Grass:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 0px 0px 6px 6px;
    background: rgb(36, 105, 36); 
    bottom: -5px;  
}