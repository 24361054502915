.Button {
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px auto;
    font-weight: bold;
    font-size: 18px;
    display: block;
    letter-spacing: 0.05em;
    border-radius: 3px;
    transition: background-color 0.4s ease;
}

.Button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.Success {
    background-color: rgb(63, 194, 134);
}

.Danger {
    background-color: rgb(230, 70, 70);
}

.Warning {
    background-color: rgb(238, 173, 20);
}

.Primary {
    background-color: rgb(56, 98, 224);
}

.Dark {
    background-color: rgb(52, 58, 63);
}

.Success:hover:enabled {
    background-color: rgb(59, 177, 124);
}

.Danger:hover:enabled {
    background-color: rgb(213, 57, 57);
}

.Warning:hover:enabled {
    background-color: rgb(235, 146, 43);
}

.Primary:hover {
    background-color: rgb(31, 67, 177);
}

.Dark:hover {
    background-color: rgb(34, 48, 42);
}
