.Modal {
    position: fixed;
    z-index: 500;
    max-width: 748px;
    height: auto;
    border: 1px solid black;
    left: calc(50% - 374px);
    top: 20%;
    box-sizing: border-box;
    border-radius: 15px;
    transition: all 0.4s ease-out;
    background-color: rgba(24, 24, 24, 0.9);
}

.Header {
    box-sizing: border-box;
    padding: 16px 24px;
}

.Close {
    position: absolute;
    color: white;
    top: 16px;
    right: 16px;
    cursor: pointer;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    border: none;
    outline: none;  
}

.Title {
    box-sizing: border-box;
    font-size: 19px;
    line-height: 22px;
    font-weight: 600;
    color: white;
}

.Body {
    padding: 0 24px 24px;
}

@media (max-width: 700px) {
    .Modal {
        max-width: 300px;
        left: calc(50% - 150px);
    }
    .Header {
        padding: 16px 10px;
    }
    .Close {
        right: 5px;
    }
    .Body {
        padding: 0 10px 10px;
    }
}

@media (max-height: 414px) {
    .Modal {
        max-width: 300px;
        left: calc(50% - 150px);
    }
    .Header {
        padding: 16px 10px;
    }
    .Close {
        right: 5px;
    }
    .Body {
        padding: 0 10px 10px;
    }
}