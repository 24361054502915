.Content {
    margin-top: 70px;
    margin-bottom: 100px;
}

/* Large smartphone */
@media (max-width: 823px) {
    .Content {
        margin-bottom: 0;
    }  
}

/* Small smartphone */
@media (max-width: 375px) {
    .Content {
        margin-bottom: 0;
    } 
}