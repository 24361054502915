.Display {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.DisplayIcon {
    margin: 5px 0 0 5px;
}

/* Icon Types */
.Square {
    width: 30px;
    height: 30px;
}

.Reqtangle-s {
    width: 75px;
    height: 30px;
}

.Reqtangle-m {
    width: 83px;
    height: 34px;
}

.Reqtangle-l {
    width: 163px;
    height: 34px;
}

.DisplayValue {
    font-size: 350%;
    font-family: 'Courier New', Courier, monospace;
    font-style: oblique;
    font-weight: bold;
    text-align: center;
    color: rgb(253, 18, 18);
}

.DisplayUnit {
    font-size: 130%;
    font-style: normal;
    text-align: right;
    font-weight: bold;
    padding: 5px;
    color: rgb(20, 137, 216);
}

@media (max-width: 1000px) {
    .Display {
        width: 100%;
    }
    .DisplayValue {
        font-size: 200%;
    }
}
