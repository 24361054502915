.Switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 10px;
}

.Switch input {
    display: none;
}

.SliderRed, .SliderBlue, .SliderWhite, .SliderLightBlue {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}
.content {
    left: 10px;
}

.SliderRed:before, .SliderBlue:before, .SliderWhite:before, .SliderLightBlue:before {
    box-sizing: border-box;
    position: absolute;
    content: attr(data-content);
    padding-left: 7px;
    padding-top: 3px;
    font-weight: 700;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

.SliderRed:before {
    background-color: rgb(214, 52, 52);
    transition: .4s;
}

.SliderBlue:before {
    background-color: rgb(52, 52, 233);
    transition: .4s;
}

input:checked + .SliderRed:before, input:checked + .SliderBlue:before, 
input:checked + .SliderWhite:before, input:checked + .SliderLightBlue:before {
    transform: translateX(26px);
    background-color: white;
    transition: .4s;
}

input:checked + .SliderRed {
    background-color: rgb(214, 52, 52);
}

input:checked + .SliderBlue {
    background-color: rgb(52, 52, 233);
}

input:checked + .SliderWhite {
    background-color: rgb(109, 104, 104);
}

input:checked + .SliderLightBlue {
    background-color: rgb(42, 153, 240);
}

.Rounded {
    border-radius: 34px; 
}
  
.Rounded:before {
    border-radius: 50%;
}
