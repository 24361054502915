.Dashboard {
    border: 1px solid #ccc;
    border-radius: 10px;
    flex: 1.2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;
}

@media (max-width: 767px) {
    .Dashboard {
        flex: 1;
    }
} 