.CardInfo {
    display: flex;
    align-items: center;
    flex-direction: column;  
} 

.CardInfo h2 {
    text-align: center;
    font-size: 1.2em;
}

.CardInfo h2:after {
    content: "";
    display: block;
    width: 60px;
    height: 2px;
    margin: 20px auto;
    background-color: red;
}

.CardLink:hover {
    transform: scale(1.03);
    transition: transform 0.25s ease-out;
}

.CardLink {
    color: black;
    display: flex;
    flex-direction: column;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    border-radius: 25px;
    overflow: hidden;
    text-decoration: none;
    transition: transform 0.25s ease-out;
}

.Img {
    width: 100%;
    overflow: hidden;
}

@media (max-width: 735px) {
    .CardInfo h2 {
        font-size: 0.9em;
    }
    .CardLink {
        border-radius: 15px;
    }
}
