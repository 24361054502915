.Light {
    position: relative;
    margin: auto;
    width: 70%;  
}

.Controls {
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-evenly;
    
}

.VideoButtons {
    display: flex;
    width: 30%;
    border: 1px solid #ccc;
    box-shadow: 0 6px 10px rgba(56, 125, 255, 0.17);
    border-radius: 10px;
}

.Icon {
    margin: 5px 0 0 5px;
}

.Youtube {
    width: 700px;
    height: 394px;
}

@media (max-width: 1200px) {
    .VideoButtons {
        width: 40%;
    }
}

@media (max-width: 900px) {
    .Controls {
        flex-direction: column;
        margin: auto;
    }
    .VideoButtons {
        margin: auto;
        width: 60%;
    }
}

@media (max-width: 700px) {
    .Youtube {
        width: 280px;
        height: 158px;
    }
}

@media (max-width: 567px) {
    .Controls {
        flex-direction: row; 
        justify-content: space-evenly;
    }
    .VideoButtons {
        width: 50%;
        margin: 0;
        flex-direction: column;
    }
}

@media (max-height: 414px) {
    .Youtube {
        width: 280px;
        height: 158px;
    }
}