.Footer {
    height: 75px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #242424;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Sponsor {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%
}

.Flag {
    background-image: url(../../asserts/sponsor/flag_yellow_low.jpg);
    background-size: cover;
    width: 60px;
    height: 40px;
}

.EdTech {
    background-image: url(../../asserts/sponsor/IMPACT_EdTech_logo_white.png);
    background-size: cover;
    width: 83px;
    height: 40px;
}

.Description {
    color: white;
    width: 45%;
    font-size: 11px;
    font-weight: 600;
}

.Copyright {
    color: white;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 10px;
    margin: 0;
}


/* Large smartphone */
@media (max-width: 823px) {
    .Footer {
        height: 120px;
        margin-top: 20px;
        position: relative;
    }
    .Description {
        width: 55%;
        font-size: 9px;
    }  
}

/* Small smartphone */
@media (max-width: 375px) {
    .Footer {
        height: 140px;
        position: relative;
    }
    .Description {
        width: 45%;
        font-size: 9px;
    }  
}
