.LightControls {
    width: 40%;
    display: flex;
    justify-content: space-around;
    margin: auto;
    padding: 10px 0;
    box-shadow: 0 6px 10px rgba(56, 125, 255, 0.17);
    border: 1px solid #ccc;
    border-radius: 20px;
}

@media (max-width: 768px) {
    .LightControls {
        width: 60%;
    }
}

@media (max-width: 567px) {
    .LightControls {
        width: 20%;
        flex-direction: column;
        align-items: center;   
    }
}