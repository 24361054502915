.Input {
    position: relative;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    float: left;
}

.InputElement {    
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px; 
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Icon {
    position: absolute;
    background-size: cover; 
    top: 8px;
    padding: 7px 5px;
}

.Reqtangle-s {
    width: 50px;
    height: 23px;
}

.Reqtangle-m {
    width: 65px;
    height: 23px;
}

.Reqtangle-l {
    width: 88px;
    height: 25px;
}

.InputElementWithIcon {
    padding-left: 95px; 
    position: relative;
}

.Invalid {
    background-color: rgb(245, 187, 187);
}

.Disabled {
    background-color: #ccc;
}

.ValidationError {
    color: red;
    position: absolute;
    font-size: 13px;
    font-weight: 700;
    margin-top: 1px;
} 
