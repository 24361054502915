.FirstOff, .SecondOff, .ThirdOff, .FourthOff, .FifthOff, 
.FirstOn, .SecondOn, .ThirdOn, .FourthOn, .FifthOn,
.FirstInHalfOn, .SecondInHalfOn, .ThirdInHalfOn, .FourthInHalfOn, .FifthInHalfOn, 
.FirstByThirdOn, .SecondByThirdOn, .ThirdByThirdOn, .FourthByThirdOn, .FifthByThirdOn {
    position: absolute;
    background: black;
    transition: 0.4s; 
    z-index: 10;
}

.FirstOn, .SecondOn, .ThirdOn, .FourthOn, .FifthOn {
    background: transparent;
}

.FirstInHalfOn, .SecondInHalfOn, .ThirdInHalfOn, .FourthInHalfOn, .FifthInHalfOn {
    background: rgb(125, 125, 125);
}

.FirstByThirdOn, .SecondByThirdOn, .ThirdByThirdOn, .FourthByThirdOn, .FifthByThirdOn {
    background: rgb(85, 85, 85);
}

.FirstOff, .FirstOn, .FirstInHalfOn, .FirstByThirdOn { 
    width: 20%;
    height: 31.5%;
    top: 67.1%;
    left: 13.5%;
}

.SecondOff, .SecondOn, .SecondInHalfOn, .SecondByThirdOn {
    width: 17%;
    height: 27.5%;
    top: 56%;
    left: 38%;
}

.ThirdOff, .ThirdOn, .ThirdInHalfOn, .ThirdByThirdOn {
    width: 15%;
    height: 24%;
    top: 47%;
    left: 58.5%;
}

.FourthOff, .FourthOn, .FourthInHalfOn, .FourthByThirdOn {
    width: 14%;
    height: 21.5%;
    top: 40%;
    left: 75%;
}

.FifthOff, .FifthOn, .FifthInHalfOn, .FifthByThirdOn {
    width: 10.7%;
    height: 18%;
    top: 35%;
    left: 89.2%;
}